import React, { useState } from "react";
import { Link } from "react-router-dom";
import { FaBars, FaTimes, FaCaretDown } from "react-icons/fa";
import "./Navbar.css";

import { useTranslation } from "react-i18next";

import enTranslation from "../../locales/en.json";
import chTranslation from "../../locales/ch.json";

import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import logo from './logo.png'

i18n.use(initReactI18next).init({
  resources: {
    en: { translation: enTranslation },
    ch: { translation: chTranslation },
  },
  lng: "ch",
  fallbacklng: "ch",
  interpolation: {
    escapeValue: false,
  },
});

const Navbar = () => {
  const { t, i18n } = useTranslation();
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  const handleDropdownOpen = () => {
    setIsOpen(true);
  };

  const handleDropdownClose = () => {
    setIsOpen(false);
  };

  const handleMenuItemClick = () => {
    if (window.innerWidth <= 960) {
      setIsOpen(false);
    }
  };

  return (
    <nav className="navb">
      <div className="navbar-container">
        <div className="nav-logo">
          <img
            src={logo}
            alt="Montreal Zion Alliance Logo"
          />
        </div>
        <Link className="text" to="/" onClick={handleMenuItemClick}>
          <p>{t("navbar.church1")}</p>
          <p>{t("navbar.church2")}</p>
        </Link>
        <div className="menu-icon" onClick={toggle}>
          {isOpen ? <FaTimes /> : <FaBars />}
        </div>
        <ul className={isOpen ? "nav-menu active" : "nav-menu"}>
          <li
            className="nav-item dropdown"
            onMouseEnter={handleDropdownOpen}
            onMouseLeave={handleDropdownClose}
          >
            <div className="nav-links">
              {t("navbar.about")}
              <FaCaretDown />
            </div>
            <ul className={isOpen ? "dropdown-menu active" : "dropdown-menu"}>
              <li>
                <Link
                  to="/About/History"
                  className="dropdown-links"
                  onClick={handleMenuItemClick}
                >
                  {t("navbar.ourHistory")}
                </Link>
              </li>
              <li>
                <Link
                  to="/About/Mission/"
                  className="dropdown-links"
                  onClick={handleMenuItemClick}
                >
                  {t("navbar.ourMission")}
                </Link>
              </li>
              <li>
                <Link
                  to="/About/Pastoral"
                  className="dropdown-links"
                  onClick={handleMenuItemClick}
                >
                  {t("navbar.pastoral")}
                </Link>
              </li>
            </ul>
          </li>
          <li className="nav-item dropdown">
            <div className="nav-links" onClick={handleDropdownOpen}>
              {t("navbar.sermons")} <FaCaretDown />
            </div>
            <ul className={isOpen ? "dropdown-menu active" : "dropdown-menu"}>
              <li>
                <Link
                  to="/Sermons/"
                  className="dropdown-links"
                  onClick={handleMenuItemClick}
                >
                  {t("navbar.audio")}
                </Link>
              </li>
              <li>
                <Link
                  to="/Videos/"
                  className="dropdown-links"
                  onClick={handleMenuItemClick}
                >
                  {t("navbar.video")}
                </Link>
              </li>
            </ul>
          </li>
          {/* <li className="nav-item">
            <Link
              to="/Ministries"
              className="nav-links"
              onClick={handleMenuItemClick}
            >
              {t("navbar.weekly")}
            </Link>
          </li> */}
          <li className="nav-item">
            <Link
              to="/Testimonials"
              className="nav-links"
              onClick={handleMenuItemClick}
            >
              {t("navbar.testimonials")}
            </Link>
          </li>
          <li className="nav-item">
            <Link
              to="/give"
              className="nav-links"
              onClick={handleMenuItemClick}
            >
              {t("navbar.giving")}
            </Link>
          </li>
          <li className="nav-item">
            <Link
              to="/contact"
              className="nav-links"
              onClick={handleMenuItemClick}
            >
              {t("navbar.contact")}
            </Link>
          </li>
        </ul>
      </div>
      <div className="btn-container">
        <button
          className="btn btn-translation"
          onClick={() => changeLanguage("en")}
        >
          English
        </button>
        <button
          className="btn btn-translation"
          onClick={() => changeLanguage("ch")}
        >
          中文
        </button>
      </div>
    </nav>
  );
};

export default Navbar;
